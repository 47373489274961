import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Menu from '../../components/navigation';
import Gallery from '../../components/gallery';

/**
 * Page
 */
const IndexPage = () => {
	return (
		<Layout>
			<SEO title="" />
			<Menu selected="projekte" />
			<div className="page-width align-center">
				<p className="spacing-big text-subheadline text-subheadline--light">
					Mit Bildern Ausdrücken Wofür Worte nicht reichen:
				</p>
				<Gallery />
			</div>
		</Layout>
	);
};

export default IndexPage;
