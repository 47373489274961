import { Link, useStaticQuery, graphql } from 'gatsby';
import React from 'react';

const Gallery = () => {
	// Get all images
	const data = {};

	useStaticQuery(graphql`
		query ProjectQuery {
			allFile(
				filter: {
					extension: { regex: "/^(jpg|png)$/" }
					dir: { regex: "/gallery$/" }
				}
			) {
				edges {
					node {
						name
						publicURL
					}
				}
			}
		}
	`)
		.allFile.edges.map((item) => item.node)
		.sort((a, b) => a.name.localeCompare(b.name))
		.forEach((item) => {
			data[item.name] = item.publicURL;
		});

	// Get image source attributes, null on failure
	const getSource = (name) => {
		if (data[name] === void 0) {
			return null;
		}

		const src = data[name];
		const name2 = name + '@2x';
		if (data[name2] === void 0) {
			return {
				src,
			};
		}
		return {
			src,
			srcSet: src + ', ' + data[name2] + ' 2x',
		};
	};

	// Render image
	const renderImage = (name, alt = '') => {
		const attributes = getSource(name);
		if (!attributes) {
			return null;
		}
		return <img {...attributes} alt={alt} />;
	};

	// Render item
	const renderItem = (link, name, rows = 1, text = '') => {
		const image = renderImage(name, text.replace(/\n/g, ' '));
		if (!image) {
			return null;
		}

		const className = 'rows-' + rows;

		if (link === null) {
			return (
				<li key={name} className={className}>
					{image}
					<span>{text}</span>
				</li>
			);
		}

		return (
			<li key={name} className={className}>
				<Link to={'/projekte/' + link + '/'}>
					{image}
					<span>{text}</span>
				</Link>
			</li>
		);
	};

	const renderEmpty = () => {
		return <li key="empty" className="empty"></li>;
	};

	const images = [
		renderItem('river', 'Opening_RIVER', 1, 'River\nNordic Design'),
		renderItem(
			'bernardi',
			'Bernardi_Opening',
			2,
			'Bernardi\nDach & Holzbau'
		),
		renderItem(
			'pflegebienen',
			'Pflegebienen_Opening',
			1,
			'Die Pflegebiene'
		),
		renderItem(
			'mue-kon',
			'mue_kon_Opening_02',
			1,
			'Mue Kon\nKonstruktionen'
		),
		renderItem('rf', 'Openings_RF', 1, 'R&F\nSteuerberatung'),
		// renderItem(null, 'Openings_Verschiedenen', 2, 'Farb- & Stil\nBeratung'),
		renderItem('fliesen-rekem', 'fr6', 2, 'Fliesen Rekem'),
		renderItem('egp', 'EGP', 1, 'Leitsystem\nEGP'),
		renderItem('leidenbergerhof', 'Openings_LH', 1, 'Leidenbergerhof'),
		renderItem('vj', 'Openings_vj', 1, 'Hochzeit\nIm Allgäu'),
		// renderItem(
		// 	null,
		// 	'Openings_DentaleManufaktur',
		// 	1,
		// 	'Dentale\nManufaktur'
		// ),
		// renderItem(null, 'Openings_Gral', 1, 'Gral\nUnternehmer\nBeratung'),
		// renderItem('fliesen-rekem', 'fr6', 2, 'Fliesen Rekem'),
		renderItem('marktgut', 'Openings_Markt', 1, 'Marktgut'),
		renderItem('illustrationen', 'Trier_Illu', 1, 'Illustrationen'),
		renderEmpty(),
	].filter((item) => item !== null);

	if (!images.length) {
		return null;
	}

	return (
		<div className="gallery">
			<ul>{images}</ul>
		</div>
	);
};

export default Gallery;
